<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full">
        <div>
          <vue-good-table
            ref="acTunnelTable"
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns"
            :rows="alarm_logs"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: serverParams.sort,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 50,
              perPageDropdown: [50, 100, 200, 500],
              dropdownAllowAll: false,
            }"
            :line-numbers="true"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
          >
            <template slot="loadingContent">
              <div class="text-center text-2xl text-primary-500"><BaseIcon icon="spinner" spin /></div>
            </template>
            <div slot="table-actions">
              <button class="btn-secondary-outline mx-1" @click="resetTable()">
                Reset
                <BaseIcon icon="redo" class="ml-1" />
              </button>
              <button class="btn-blue mx-1" @click="exportXLS()">
                Export
                <BaseIcon icon="file-excel" class="ml-1" />
              </button>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'delivery_status'">
                <template v-for="(v, i) in props.row.delivery_status">
                  <div v-bind:key="i" v-if="v.status != 'queued'">
                    <span class="rounded-lg bg-gray-600 text-white text-xxs px-1 uppercase mr-2">{{ v.order }}</span>
                    <span>{{ v.id }}</span>
                    <!-- <span v-if="v.status == 'queued'" class="rounded-lg bg-secondary-500 text-white text-xs px-2 ml-2">Kö</span> -->
                    <span v-if="v.status == 'sent'" class="rounded-lg bg-yellow-500 text-white text-xs px-2 ml-2">Skickat</span>
                    <span v-else-if="v.status == 'delivered'" class="rounded-lg bg-accent-500 text-white text-xs px-2 ml-2">Levereras</span>
                    <span v-else-if="v.status == 'not_sent'" class="rounded-lg bg-red-600 text-white text-xs px-2 ml-2">Inte skickat</span>
                    <span v-else-if="v.status == 'failed'" class="rounded-lg bg-red-500 text-white text-xs px-2 ml-2">Misslyckades</span>
                  </div>
                </template>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <span v-if="props.row.status == 'pending'" class="rounded-lg bg-yellow-500 text-white text-xs px-2 ml-2">Pending</span>
                <span v-if="props.row.status == 'complete'" class="rounded-lg bg-accent-500 text-white text-xs px-2 ml-2">Complete</span>
                <span v-else-if="props.row.status == 'failed'" class="rounded-lg bg-red-500 text-white text-xs px-2 ml-2">Failed</span>
              </span>
              <span v-else-if="props.column.field == 'view' && (user.role == 'admin' || user.role == 'seller')">
                <button class="btn-blue-outline" @click.prevent="showAlarmCenterReportModal(props.row)">Visa</button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>

    <modal name="modal-alarm-center-report-detail" class="modal-inner modal-alarm-center-report-detail" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="200" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
      <div class="px-3 py-3" v-if="alarmReportDetail != null">
        <div class="flex-col px-2 pt-2 pb-6 border border-secondary-400 rounded-sm text-sm">
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              IMEI:
            </div>
            <div class="float-right">
              {{ alarmReportDetail.imei_number }}
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Telematiknummer:
            </div>
            <div class="float-right">
              {{ alarmReportDetail.phone }}
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Verksamhetsnamn:
            </div>
            <div class="float-right">
              {{ getUnitName(alarmReportDetail.unit_id) }}
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Larmcentral:
            </div>
            <div class="float-right">
              {{ getAlarmCenterName(alarmReportDetail.alarm_center_id) }}
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Typ:
            </div>
            <div class="float-right">
              <span>{{ getReportTypeName(alarmReportDetail.log_type) }}</span>
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Status:
            </div>
            <div class="float-right">
              <span v-if="alarmReportDetail.status == 'pending'" class="rounded-lg bg-yellow-500 text-white text-xs px-2 ml-2">Pending</span>
              <span v-if="alarmReportDetail.status == 'complete'" class="rounded-lg bg-accent-500 text-white text-xs px-2 ml-2">Complete</span>
              <span v-else-if="alarmReportDetail.status == 'failed'" class="rounded-lg bg-red-500 text-white text-xs px-2 ml-2">Failed</span>
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Receipts:
            </div>
            <div class="float-right">
              <div v-for="(v, i) in alarmReportDetail.delivery_status" :key="i" class="mt-1">
                <span class="rounded-lg bg-gray-600 text-white text-xxs px-1 uppercase mr-2">{{ v.order }}</span>
                <span class="rounded-lg bg-primary-500 text-white text-xxs px-1 uppercase mr-2">{{ v.type }}</span>
                <span>{{ v.id }}</span>
                <span v-if="v.status == 'queued'" class="rounded-lg bg-secondary-500 text-white text-xs px-2 ml-2">Kö</span>
                <span v-if="v.status == 'sent'" class="rounded-lg bg-yellow-500 text-white text-xs px-2 ml-2">Skickat</span>
                <span v-else-if="v.status == 'delivered'" class="rounded-lg bg-accent-500 text-white text-xs px-2 ml-2">Levereras</span>
                <span v-else-if="v.status == 'not_sent'" class="rounded-lg bg-red-600 text-white text-xs px-2 ml-2">Inte skickat</span>
                <span v-else-if="v.status == 'failed'" class="rounded-lg bg-red-500 text-white text-xs px-2 ml-2">Misslyckades</span>
                <span v-if="v.timestamp" class="text-xxs text-secondary-500 ml-2">{{ formatDate(v.timestamp) }}</span>
                <span v-if="v.report" class="text-xxs text-secondary-500 ml-2">
                  <p class="text-xs font-sans whitespace-pre-wrap text-xxs text-secondary-500">{{ v.report }}</p>
                </span>
              </div>
            </div>
          </div>
          <div v-if="alarmReportDetail.report_string" class="clear-both py-2">
            <div class="float-left font-semibold">
              OVLS String:
            </div>
            <div class="float-right">
              <pre class="text-xs font-sans whitespace-pre-wrap text-xxs" v-html="alarmReportDetail.report_string"></pre>
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Log:
            </div>
            <div class="float-right">
              <div v-for="(l, i) in alarmReportDetail.log" :key="i" class="text-xs">
                <span class="text-xxs text-secondary-500 mr-2">{{ formatDate(l.timestamp) }}</span>
                {{ l.message }}
              </div>
            </div>
          </div>
          <div class="clear-both py-2">
            <div class="float-left font-semibold">
              Datum:
            </div>
            <div class="float-right">
              {{ this.formatDate(alarmReportDetail.createdAt) }}
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const MODAL_WIDTH = 700;

export default {
  name: "AlarmCenterReportLog",
  title() {
    return `Larm via SecurCloud`;
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      totalRecords: 0,
      alarm_logs: [],
      units: [],
      alarm_centers: [],
      columns: [
        { label: "IMEI", field: "imei_number" },
        { label: "Telematiknummer", field: "phone" },
        { label: "Verksamhetsnamn", field: "unit_id", formatFn: this.getUnitName },
        { label: "Typ", field: "log_type", formatFn: this.getReportTypeName, sortable: true },
        { label: "Status", field: "status", sortable: true },
        { label: "Receipts", field: "delivery_status" },
        { label: "Datum", field: "createdAt", type: "date", formatFn: this.formatDate, sortable: true },
        { label: "", field: "view" },
      ],
      alarmReportDetail: null,
      serverParams: {
        filters: {},
        sort: [{ field: "createdAt", type: "desc" }],
        page: 1,
        perPage: 50,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    async getAlarmCenterReportLog() {
      try {
        let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm-center/securcloud/report`, this.serverParams);
        this.alarm_logs = response.data.alarm_logs;
        this.totalRecords = response.data.totalRecords;
      } catch (error) {
        this.handleError(error);
      }
    },

    getListCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/customer`)
        .then((response) => {
          this.units = response.data.units;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getListAlarmCenter() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/alarm-center`)
        .then((response) => {
          this.alarm_centers = response.data.alarm_centers;
          let alarmcenter_names = _.map(this.alarm_centers, function(o) {
            return { value: o._id, text: o.name };
          });
          let alarmcenter_col = _.find(this.columns, { field: "alarm_center_id" });
          alarmcenter_col.filterOptions.filterDropdownItems = alarmcenter_names;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    exportXLS() {
      let export_columns = [];

      this.alarm_logs.forEach((log) => {
        export_columns.push({
          IMEI: log.imei_number,
          Telematiknummer: log.phone,
          Typ: this.getReportTypeName(log.log_type),
          Datum: this.formatDate(log.createdAt),
        });
      });

      this.excelExport(export_columns, "Alarms via SecurCloud", "Alarms-via-SecurCloud");
    },

    getReportTypeName(type) {
      if (type == "push_button") return "Skarptlarm";
      else if (type == "man_down") return "Man down";
      else if (type == "timer_alarm_stop") return "LarmTimer";
      else if (type == "call_b") return "Internlarm";
      else return type;
    },

    getUnitName(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) return u.name;
      else return "";
    },

    getAlarmCenterName(_id) {
      let a = _.find(this.alarm_centers, { _id: _id });
      if (a) return a.name;
      else return "";
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAlarmCenterReportLog();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.getAlarmCenterReportLog();
    },

    onSortChange(params) {
      this.updateParams({ sort: params });
      this.getAlarmCenterReportLog();
    },

    onColumnFilter(params) {
      let filters = params.columnFilters;

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (filters[key] == "") delete filters[key];
        }
      }

      this.updateParams({ filters, page: 1 });
      this.getAlarmCenterReportLog();
    },

    resetTable() {
      this.serverParams.filters = {};
      this.serverParams.sort = { field: "createdAt", type: "desc" };
      this.serverParams.page = 1;
      this.$refs["acTunnelTable"].globalSearchTerm = "";
      this.$refs["acTunnelTable"].reset();
    },

    setupTableColumns() {
      if (this.user.role == "admin" || this.user.role == "seller") {
        this.columns = [
          { label: "IMEI", field: "imei_number" },
          { label: "Telematiknummer", field: "phone" },
          { label: "Verksamhetsnamn", field: "unit_id", formatFn: this.getUnitName },
          { label: "Larmcentral", field: "alarm_center_id", formatFn: this.getAlarmCenterName, filterOptions: { enabled: true, placeholder: "Larmcentral", filterDropdownItems: [] } },
          { label: "Typ", field: "log_type", formatFn: this.getReportTypeName, sortable: true },
          { label: "Status", field: "status", sortable: true },
          { label: "Receipts", field: "delivery_status" },
          { label: "Datum", field: "createdAt", type: "date", formatFn: this.formatDate, sortable: true },
          { label: "", field: "view" },
        ];
      }
    },

    showAlarmCenterReportModal(log) {
      this.alarmReportDetail = _.cloneDeep(log);
      this.$modal.show("modal-alarm-center-report-detail");
    },

    formatDate(date) {
      if (this.user.role == "admin") return this.moment(date).format("YYYY-MM-DD HH:mm:ss");
      else return this.moment(date).format("YYYY-MM-DD HH:mm");
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    this.setupTableColumns();
    this.getListCustomer();

    if (this.user.role == "admin" || this.user.role == "seller") this.getListAlarmCenter();

    this.setPageTitle(`Larm via SecurCloud`, "alarm_center_email_log");
  },
};
</script>
